<!-- @format -->
<script lang="ts">
  import { getStoresContext } from "~/stores";
  import type { StoresKey } from "~/stores";
  import Logo from "./Logo.svelte";

  export let request_store_key: StoresKey;

  const { locale, nav_menus, i18nOf, reverse_router, T, site } =
    getStoresContext(request_store_key);
    
  $: logo = $site.logo;
  $: shoppingCenterName = $site.shoppingCenterName;

  const notExcluded = (locale: string) => (item: NavigationMenuItem) => {
    return !item.excluded_locales?.some((x) => x == locale);
  };

  $: secondary = $nav_menus.find((nav) => nav.navigationType == "Secondary");

  $: social_media_links =
    $nav_menus && $nav_menus.find((nav) => nav.navigationType == "Social");

  $: social = social_media_links && social_media_links.navigationMenuItems;
  $: nav_items = secondary?.navigationMenuItems || [];
  $: urlPages = nav_items
    .filter((link) => link.type == "url")
    .filter(notExcluded($locale));
  $: internalPages = nav_items
    .filter((item, index) => index != 0 && item.type == "page")
    .filter(notExcluded($locale));
  $: facebook = social && social.find((so) => so.name == "Facebook");
  $: instagram = social && social.find((so) => so.name == "Instagram");
  $: youtube = social && social.find((so) => so.name == "YouTube");
  $: t = $T;
</script>

<footer>
  <div
    class="py-5 pt-lg-6 pb-lg-4 pb-5 flex-wrap container-fluid container-fluid-max-xl">
    <div class="row">
      <div class="col-lg-4 col-12">
        <Logo {request_store_key} url={logo} isNavbarLogo={false} color="white" />
        <div class="mb-4" />
        <div
          class="d-none d-lg-block mb-3"
          style="position: absolute;bottom: 0;">
          <p class="mb-3">{t`follow us on social media`}</p>
          <div style="display: flex;">
            {#if facebook}
              <a
                href={facebook.url}
                target="_blank"
                rel="noreferrer"
                class="mr-3">
                <img
                  src="/theme/eften2025/images/Facebook.png"
                  alt="facebook-icon"
                  class="SoMe-icon"
                  width="32" />
              </a>
            {/if}
            {#if instagram}
              <a
                href={instagram.url}
                target="_blank"
                rel="noreferrer"
                class="mr-3">
                <img
                  src="/theme/eften2025/images/Instagram.png"
                  alt="instagram-icon"
                  class="SoMe-icon"
                  width="32" />
              </a>
            {/if}
            {#if youtube}
              <a
                href={youtube.url}
                target="_blank"
                rel="noreferrer"
                class="mr-3">
                <img
                  src="/theme/eften2025/images/Youtube.png"
                  alt="youtube-icon"
                  class="SoMe-icon"
                  width="32" />
              </a>
            {/if}
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-12">
        <ul class="list pl-0">
          {#each internalPages as item, i}
            {#if i == Math.ceil(internalPages.length / 2)}
              <div class="d-lg-none w-100 separator" />
            {/if}
            <li class="mb-3">
              <a href={$reverse_router.routePage(item.link)}>
                {i18nOf(item.name_i18n)}
              </a>
            </li>
          {/each}
        </ul>
      </div>
      <div class="col-12 col-lg-3">
        <div class="d-lg-none col-12 separator" />
        {#each urlPages as item}
          <li class="mb-3">
            <a href={item.url} target="_blank" rel="noreferrer">
              <span class="fw-500 external-link">
                {i18nOf(item.name_i18n)}
              </span>
              <img
                src="/theme/eften2025/images/Arrow-external.png"
                alt="arrow external"
                class="arrow-external"
                width="30" />
            </a>
          </li>
        {/each}
      </div>

      <div class="col-12 d-lg-none">
        <div class="mt-5 mb-lg-0 mb-4">
          <p class="mb-3">{t`follow us on social media`}</p>
          <div style="display: flex;">
            {#if facebook}
              <a
                href={facebook.url}
                target="_blank"
                rel="noreferrer"
                class="mr-3">
                <img
                  src="/theme/eften2025/images/Facebook.png"
                  alt="facebook-icon"
                  width="32"
                  class="SoMe-icon" />
              </a>
            {/if}
            {#if instagram}
              <a
                href={instagram.url}
                target="_blank"
                rel="noreferrer"
                class="mr-3">
                <img
                  src="/theme/eften2025/images/Instagram.png"
                  alt="instagram-icon"
                  class="SoMe-icon"
                  width="32" />
              </a>
            {/if}
            {#if youtube}
              <a
              href={youtube.url}
              target="_blank"
              rel="noreferrer"
              class="mr-3">
                <img
                src="/theme/eften2025/images/Youtube.png"
                alt="youtube-icon"
                class="SoMe-icon"
                width="32" />
              </a>
            {/if}
          </div>
        </div>
      </div>
    </div>
    <div class="text-left text-lg-right pb-3 copyright">
      © {shoppingCenterName}
      {new Date().getFullYear()}
      <a
        href="http://www.hyperin.com/"
        class="mx-2"
        target="_blank"
        rel="noreferrer">Powered by HyperIn</a>
    </div>
  </div>
</footer>

<style lang="scss">
  .list {
    column-count: 1;
    margin: 0;

    @media screen and (min-width: 992px) {
      column-count: 2;
    }
  }
  footer {
    color: #fff;
    background: var(--brandColor1);
  }
  a {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    @media screen and (min-width: 768px) {
      font-size: 20px;
    }
  }
  a:hover {
    color: #fff;
    text-decoration: none;
  }
  .separator {
    border: 1px solid var(--color-gray);
    width: 100%;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
  }
  li {
    list-style: none;
  }

  .arrow-external {
    filter: invert(100%);
    width: 25px;
    @media screen and (min-width: 425px) {
      width: 30px;
    }
  }

  .external-link {
    @media screen and (max-width: 375px) {
      font-size: 1rem;
    }
  }
  .copyright {
    font-size: 16px;
    a {
      font-size: 16px;
    }
  }
</style>
